import { Link } from "react-router-dom";
import CarsayoLogo from "../../components/icons/CarsayoLogo";
import AppleIcon from "../../components/icons/AppleIcon";
import GoogleIcon from "../../components/icons/GoogleIcon";
import { cn } from "../../utils";

const Section01 = () => {
  return (
    <section className="py-[50px] px-5">
      <div className="flex flex-col justify-start items-start gap-2.5 mb-[27px]">
        <strong className="text-[22px] leading-[35.2px] font-[600]">
          자동차 AI 라인업 플랫폼
        </strong>
        <p className="text-[16px] leading-[25.6px] font-[500] break-keep">
          카사요가 구축한 자동차 생태계와 연결형 AI 서비스로 새로운 경험을
          시작하세요.
        </p>
      </div>
      <ul className="w-full grid grid-cols-1 gap-5">
        <li
          style={{
            backgroundImage: "url(/imgs/main_bg01.png)",
          }}
          className="w-full flex flex-col justify-start items-start overflow-hidden relative rounded-[16px] border-[2px] border-white min-h-[400px] bg-no-repeat bg-center bg-cover"
        >
          <div className="w-full relative bg-white bg-opacity-50 backdrop-blur-[10px] p-5 pb-4 flex justify-start items-stretch">
            <img
              src="/imgs/carsayo.png"
              alt="카사요 심볼"
              className="w-[50px] h-[50px] object-contain"
            />
            <div className="pl-4 flex flex-col justify-start items-start gap-1">
              <strong className="text-[16px] leading-[24px] font-[600] inline-block">
                카사요
              </strong>
              <p className="text-[14px] leading-[21px] font-[500] mb-1.5 break-keep">
                신차 구매와 내 차 팔기,
                <br />
                자동차 금융/보험/사고수리까지 한번에
              </p>
              <div className="flex justify-start items-center gap-1.5 shrink-0 whitespace-nowrap">
                <span className="bg-[#E1E6F7] text-[13px] leading-[19.5px] font-[600] py-1.5 px-2 rounded-[6px] inline-block ml-[-2px]">
                  일반 고객용
                </span>
                <span className="bg-[#E1E6F7] text-[13px] leading-[19.5px] font-[600] py-1.5 px-2 rounded-[6px] inline-block ml-[-2px]">
                  카 매니저 고객용
                </span>
              </div>
            </div>
          </div>
          <div className="px-5 pb-5 w-full flex-1 flex justify-center items-end absolute z-[2] bottom-0">
            <div className="w-full rounded-[12px] bg-gradient-to-r from-[#3F68FFCC] to-[#22CFC7CC] p-0.5">
              <ul className="flex w-full items-center justify-center rounded-[10px] bg-white backdrop-blur-[20px]">
                <li className="w-full">
                  <Link
                    to={
                      "https://apps.apple.com/kr/app/카사요-리스-장기렌트-신차구매-중고차팔기-비교견적/id1546852990"
                    }
                    target="_blank"
                    rel="noopener noreferrer"
                    className={cn(
                      "w-full flex justify-center items-center gap-1 py-[15px] relative text-[#111] text-[14px] font-[400]",
                      "after:absolute after:right-0 after:top-1/2 after:h-[24px] after:w-[1px] after:-translate-y-1/2 after:bg-[#DDE0E7] after:content-['']"
                    )}
                  >
                    <AppleIcon fill="#111" />
                    <span>App Store</span>
                  </Link>
                </li>
                <li className="w-full">
                  <Link
                    to={
                      "https://play.google.com/store/apps/details?id=com.ksapp.carsayo"
                    }
                    target="_blank"
                    rel="noopener noreferrer"
                    className="w-full flex justify-center items-center gap-1 py-[15px] text-[#111] text-[14px] font-[400]"
                  >
                    <GoogleIcon />
                    <span>Play Store</span>
                  </Link>
                </li>
              </ul>
            </div>
          </div>
          <div className="w-full min-h-[252px] relative bottom-0 left-0 py-[42px] overflow-hidden">
            <img
              src="/imgs/main_card01_icon01.png"
              alt="자동차 이미지"
              className="min-w-[170px] max-w-[218px] object-contain absolute bottom-0 left-0 z-[1]"
            />
            <img
              src="/imgs/main_card01_icon02.png"
              alt="종이 이미지"
              className="w-[16dvw] min-w-[50px] max-w-[60px] object-contain absolute bottom-[133px] left-0 z-[1]"
            />
            <img
              src="/imgs/main_card01_icon03.png"
              alt="메달 이미지"
              className="w-[13dvw] min-w-[48px] max-w-[52px] object-contain absolute bottom-[92px] right-[12dvw]"
            />
            <img
              src="/imgs/main_card01_icon04.png"
              alt="노트 이미지"
              className="w-[18dvw] min-w-[60px] max-w-[70px] object-contain absolute bottom-[140px] right-0"
            />
          </div>
        </li>
        <li
          style={{
            backgroundImage: "url(/imgs/main_bg02.png)",
          }}
          className="w-full flex flex-col justify-start items-start overflow-hidden relative rounded-[16px] border-[2px] border-white min-h-[400px] bg-no-repeat bg-center bg-cover"
        >
          <div className="w-full relative z-[1] bg-white bg-opacity-50 backdrop-blur-[10px] p-5 pb-4 flex justify-start items-stretch">
            <img
              src="/imgs/partners.png"
              alt="카사요파트너스 심볼"
              className="w-[50px] h-[50px] object-contain"
            />
            <div className="pl-4 flex flex-col justify-start items-start gap-1">
              <strong className="text-[16px] leading-[24px] font-[600]">
                카사요 파트너스
              </strong>
              <p className="text-[14px] leading-[21px] font-[500] mb-1.5 break-keep">
                AI 자동차 사고수리 올인원 플랫폼
                <br />
                사고 접수부터 단골 고객 관리
              </p>
              <span className="bg-[#E0F2FA] text-[13px] leading-[19.5px] font-[600] py-1.5 px-2 rounded-[6px] inline-block ml-[-2px]">
                공업사 사장님 전용
              </span>
            </div>
          </div>
          <div className="px-5 pb-5 w-full flex-1 flex justify-center items-end absolute z-[1] bottom-0">
            <div className="w-full rounded-[12px] bg-gradient-to-r from-[#3F68FFCC] to-[#22CFC7CC] p-0.5">
              <ul className="flex w-full items-center justify-center rounded-[10px] bg-white backdrop-blur-[20px]">
                <li className="w-full">
                  <Link
                    to={
                      "https://apps.apple.com/kr/app/%EC%B9%B4%EC%82%AC%EC%9A%94-%ED%8C%8C%ED%8A%B8%EB%84%88%EC%8A%A4-%EC%9E%90%EB%8F%99%EC%B0%A8-%EC%A0%95%EB%B9%84%EC%86%8C-%EC%82%AC%EC%9E%A5%EB%8B%98-%EC%A0%84%EC%9A%A9/id6740305302"
                    }
                    target="_blank"
                    rel="noopener noreferrer"
                    className={cn(
                      "w-full flex justify-center items-center gap-1 py-[15px] relative text-[#111] text-[14px] font-[400]",
                      "after:absolute after:right-0 after:top-1/2 after:h-[24px] after:w-[1px] after:-translate-y-1/2 after:bg-[#DDE0E7] after:content-['']"
                    )}
                  >
                    <AppleIcon fill="#111" />
                    <span>App Store</span>
                  </Link>
                </li>
                <li className="w-full">
                  <Link
                    to={
                      "https://play.google.com/store/apps/details?id=com.carsayo.partners&hl=ko"
                    }
                    target="_blank"
                    rel="noopener noreferrer"
                    className="w-full flex justify-center items-center gap-1 py-[15px] text-[#111] text-[14px] font-[400]"
                  >
                    <GoogleIcon />
                    <span>Play Store</span>
                  </Link>
                </li>
              </ul>
            </div>
          </div>
          <div className="w-full min-h-[252px] relative bottom-0 left-0 py-[42px]">
            <img
              src="/imgs/main_card02_icon01.png"
              alt="자동차 이미지"
              className="w-[51dvw] min-w-[180px] max-w-[192px] object-contain absolute bottom-[37px] right-0"
            />
            <img
              src="/imgs/main_card02_icon02.png"
              alt="스패너 이미지"
              className="w-[25dvw] min-w-[70px] max-w-[80px] object-contain absolute bottom-[9px] left-0"
            />
          </div>
        </li>
        <li
          style={{
            backgroundImage: "url(/imgs/main_bg03.png)",
          }}
          className="w-full flex flex-col justify-start items-start overflow-hidden relative rounded-[16px] border-[2px] border-white min-h-[400px] bg-no-repeat bg-center bg-cover"
        >
          <div className="w-full relative bg-white bg-opacity-50 backdrop-blur-[10px] p-5 pb-4 flex justify-start items-stretch">
            <img
              src="/imgs/insurance.png"
              alt="카사요인슈어런스 심볼"
              className="w-[50px] h-[50px] object-contain"
            />
            <div className="pl-4 flex flex-col justify-start items-start gap-1">
              <strong className="text-[16px] leading-[24px] font-[600]">
                카사요 인슈어런스
              </strong>
              <p className="text-[14px] leading-[21px] font-[500] mb-1.5 break-keep">
                보험영업 및 보험설계 서비스
                <br />
                CRM 그 이상의 고객 관리
              </p>
              <span className="bg-[#DFEFEF] text-[13px] leading-[19.5px] font-[600] py-1.5 px-2 rounded-[6px] inline-block ml-[-2px]">
                보험 설계사 및 카 매니저 전용
              </span>
            </div>
          </div>
          <div className="px-5 pb-5 w-full flex-1 flex justify-center items-end absolute z-[1] bottom-0">
            <div className="w-full rounded-[12px] bg-gradient-to-r from-[#3F68FFCC] to-[#22CFC7CC] p-0.5">
              <ul className="flex w-full items-center justify-center rounded-[10px] bg-white backdrop-blur-[20px]">
                <li className="w-full">
                  <Link
                    to={
                      "https://apps.apple.com/kr/app/카사요-인슈어런스/id6740797750"
                    }
                    target="_blank"
                    className={cn(
                      "w-full flex justify-center items-center gap-1 py-[15px] relative text-[#111] text-[14px] font-[400]",
                      "after:absolute after:right-0 after:top-1/2 after:h-[24px] after:w-[1px] after:-translate-y-1/2 after:bg-[#DDE0E7] after:content-['']"
                    )}
                  >
                    <AppleIcon fill="#111" />
                    <span>App Store</span>
                  </Link>
                </li>
                <li className="w-full">
                  <Link
                    to={
                      "https://play.google.com/store/apps/details?id=com.carsayo.insurance"
                    }
                    target="_blank"
                    className="w-full flex justify-center items-center gap-1 py-[15px] text-[#111] text-[14px] font-[400]"
                  >
                    <GoogleIcon />
                    <span>Play Store</span>
                  </Link>
                </li>
              </ul>
            </div>
          </div>
          <div className="w-full min-h-[252px] relative bottom-0 left-0 py-[42px]">
            <img
              src="/imgs/main_card03_icon01.png"
              alt="자동차 이미지"
              className="w-[43dvw] min-w-[150px] max-w-[164px] object-contain absolute bottom-[83px] left-0"
            />
            <img
              src="/imgs/main_card03_icon02.png"
              alt="사람 이미지"
              className="w-[130px] object-contain absolute bottom-0 right-[11px]"
            />
          </div>
        </li>
      </ul>
      <Link
        to={"https://carsayo.net/"}
        target="_blank"
        rel="noopener noreferrer"
        className="w-full py-[18px] px-5 bg-white flex justify-between items-center gap-2 h-[60px] rounded-[16px] mt-5 shadow-[0px_10px_50px_#DBEEEE]"
      >
        <span className="text-[16px] leading-[16px] font-[600] text-[#494AF1]">
          카사요로 이동
        </span>
        <img
          src="/imgs/arrow.png"
          alt="화살표"
          className="w-6 h-6 object-contain"
        />
      </Link>
    </section>
  );
};

const Footer = () => {
  return (
    <footer className="w-full flex-none border-t border-[#EEEEEE] bg-[#F8F9FA]">
      <div className="flex h-[70px] items-center justify-between border-b border-[#EEEEEE] px-5">
        <Link to="/" className="shrink-0">
          <CarsayoLogo width="120" height="20" />
        </Link>
      </div>
      <div className="space-y-6 px-5 py-6">
        {/* 텍스트 영역 */}
        <div className="space-y-[14px] text-[13px] font-normal leading-[13px] tracking-[-0.325px] text-[#555]">
          <div>대표이사 : 최용민</div>
          <div>주소 : (04790) 서울특별시 성동구 아차산로 49, 1403호</div>
          <div>벤처기업 20240514030044 (혁신성장형)</div>
          <div>사업자등록번호 : 142-87-01847</div>
          <div>통신판매업신고 : 제 2021-서울성동-00533</div>
          <div>광고 · 문의 메일 : info@carsayo.net</div>
        </div>

        {/* 전화번호 및 앱 배너 */}
        <div className="flex justify-between gap-4">
          <div className="w-[192px] shrink-0 bg-[#F2F4F6] rounded-[6px] p-3.5 space-y-3">
            <strong className="mb-0.5 text-[#222] font-semibold text-[15px] leading-[15px] tracking-[-0.375px]">
              1668-1571
            </strong>
            <p className="flex justify-between items-center text-[#222] font-normal text-[13px] leading-[13px] tracking-[-0.325px]">
              <span>평일</span>
              <span className="font-[500]">10:00~18:00</span>
            </p>
            <p className="flex justify-between items-center text-[#222] font-normal text-[13px] leading-[13px] tracking-[-0.325px]">
              <span>점심시간</span>
              <span className="font-[500]">13:00~14:00</span>
            </p>
            <p className="flex justify-between items-center text-[#222] font-normal text-[13px] leading-[13px] tracking-[-0.325px]">
              <span>휴무</span>
              <span className="font-[500]">주말 및 공휴일</span>
            </p>
          </div>

          <div className="w-full grid grid-rows-2 gap-4">
            <Link
              to={
                "https://apps.apple.com/kr/app/카사요-리스-장기렌트-신차구매-중고차팔기-비교견적/id1546852990"
              }
              target="_blank"
              className="flex justify-center items-center gap-1 rounded-[6px] border border-[var(--Line-Regular_Color,#E5E5EC)] bg-[#FFF] px-4"
            >
              <AppleIcon fill="#111111" />
              <div className="text-[14px] font-normal leading-[14px] tracking-[-0.35px] tiny:hidden">
                App Store
              </div>
            </Link>

            <Link
              to={
                "https://play.google.com/store/apps/details?id=com.ksapp.carsayo"
              }
              target="_blank"
              className="flex justify-center items-center gap-1 rounded-[6px] border border-[var(--Line-Regular_Color,#E5E5EC)] bg-[#FFF] px-4"
            >
              <GoogleIcon />
              <div className="text-[14px] font-normal leading-[14px] tracking-[-0.35px] tiny:hidden">
                Play Store
              </div>
            </Link>
          </div>
        </div>
      </div>

      <div className="flex h-[60px] items-center border-t border-[#EEEEEE] px-5 text-[13px] font-normal leading-[18px] tracking-[-0.325px] text-[#555]">
        Copyright ⓒ 카사요(주) ALL RIGHTS RESERVED.
      </div>
      <div className="flex h-[70px] w-full items-center justify-end border-t border-[#EEEEEE] px-[14px]">
        <div className="flex items-center justify-center gap-[6px] py-[14px]">
          <Link
            to={"https://www.smes.go.kr/venturein/institution/ventureGuide"}
            target="_blank"
            rel="noopener noreferrer"
            className="mr-[3px] flex h-[20px] w-[70px] items-center justify-center"
          >
            <img
              src="/imgs/venture_mark.png"
              alt="벤처확인기업마크"
              className="w-full object-contain"
            />
          </Link>
          <Link
            to={"https://www.ripc.org/ipcert/introduce"}
            target="_blank"
            rel="noopener noreferrer"
            className="mr-[3px] flex h-[20px] w-[102px] items-center justify-center"
          >
            <img
              src="/imgs/cert_mark.png"
              alt="지식재산경영인증마크"
              className="w-full object-contain"
            />
          </Link>
          <Link
            to={"https://www.kca.go.kr/ccm/certSystemOutlineView.do"}
            target="_blank"
            rel="noopener noreferrer"
            className="flex h-[42px] w-[42px] items-center justify-center"
          >
            <img
              src="/imgs/ccm_mark.png"
              alt="소비자중심경영인증마크"
              className="w-full object-contain"
            />
          </Link>
          <Link
            to={"https://www.ffsb.kr/"}
            target="_blank"
            className="flex h-[42px] w-[42px] items-center justify-center"
          >
            <img
              src="/imgs/family_friendly_mark.png"
              alt="가족친화인증마크"
              className="w-full object-contain"
            />
          </Link>
        </div>
      </div>
    </footer>
  );
};

export default function MainPage() {
  return (
    <div className="w-full bg-gradient-to-b from-[#DFE4FF] to-[#C7E4FF] min-h-[calc(100dvh-60px)] flex flex-col justify-between items-start">
      <main className="w-full">
        <Section01 />
      </main>
      <Footer />
    </div>
  );
}
