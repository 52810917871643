import "./App.css";
import MainLayout from "./pages/layout";
import { BrowserRouter as Router } from "react-router-dom";
import { useEffect } from "react";

const DisablePWAInstallPrompt = () => {
  useEffect(() => {
    const handler = (event: Event) => {
      event.preventDefault(); // 설치 권유 배너 차단
    };

    window.addEventListener("beforeinstallprompt", handler);
    return () => {
      window.removeEventListener("beforeinstallprompt", handler);
    };
  }, []);

  return null;
};

function App() {
  return (
    <Router>
      <DisablePWAInstallPrompt />
      <MainLayout />
    </Router>
  );
}

export default App;
